import React from "react";
import { useMediaQuery } from "react-responsive";

export const Section2Item = ({ title, description, image }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <section className="d-flex w-lg-100">
      <div
        className="align-self-center"
        style={{ width: large ? "80%" : "100%" }}
      >
        <div className="text-center text-lg-start py-4 ps-lg-0 my-5 my-lg-none">
          <div className="title-2 text-extrabold">{title}</div>
          <div className="my-4" style={{ width: large ? "80%" : "100%" }}>
            <p className="">{description}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2Item;
