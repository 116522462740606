import React from "react";

import { Container, Spinner } from "react-bootstrap";

const Loader = ({ faded }) => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
      style={{
        background: faded ? "rgba(255, 255, 255, 0.8)" : "#FFF",
        ...styles.parentContainer,
      }}
    >
      <Spinner animation="border" style={{ color: "#555" }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  );
};

const styles = {
  parentContainer: {
    zIndex: 9999,
    top: 0,
    bottom: 0,
  },
};

export default Loader;
