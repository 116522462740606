import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  LinkedInLogo_bw,
  LinkedInLogo_color,
} from "web/assets";

const Footer = ({ background_lg = "" }) => {
  const [showColor, setShowColor] = useState({
    facebook: false,
    linkedin: false,
    twitter: false,
    instagram: false,
  });

  return (
    <Container>
      <Row xs={1} lg={2} className="my-4">
        <Col>
          <div className="d-lg-flex align-items-center mb-lg-0">
            <div className="me-3 mb-3 mb-lg-0 text-bold">
              <p>Follow us for updates</p>
            </div>
            <div className="d-flex justify-content-start">
              <div className="me-3">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/own-experiences/"
                >
                  <Image
                    src={
                      showColor.linkedin ? LinkedInLogo_color : LinkedInLogo_bw
                    }
                    onMouseEnter={() => setShowColor({ linkedin: true })}
                    onMouseLeave={() => setShowColor({ linkedin: false })}
                  />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
