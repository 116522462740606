import { useRef, useState } from "react";
import { Button, Container, Form} from "react-bootstrap";
import { Footer, NavbarMain } from "web/components";
import { FooterBox_white } from "web/assets";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { notify } from "web/action";

const ContactUs = ({ type, submitButtonTitle }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(formRef.current);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      company: formData.get("company"),
      type: type,
    };
    try {
      const response = await axios.post("/submit-contact", data);
      if (response.status === 200) {
        notify("Thank you for connecting with us!", "success");
        formRef.current.reset();
      }
    } catch (error) {
      notify("Some error has occurred.", "error")
      // console.error("Error submitting form", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1" htmlFor="name">
            <p className="text-3 mb-0">Name</p>
          </Form.Label>
          <Form.Control
            id="name"
            name="name"
            aria-describedby=""
            type="text"
            placeholder="Enter Your Name"
            className="p-2 mb-2 focus-radius border border-secondary rad-8"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1" htmlFor="email">
            <p className="text-3 mb-0">Email Id</p>
          </Form.Label>
          <Form.Control
            id="email"
            name="email"
            aria-describedby=""
            type="email"
            placeholder="Enter Your Email"
            className="p-2 mb-2 focus-radius border border-secondary rad-8"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1" htmlFor="company">
            <p className="text-3 mb-0">Company Name</p>
          </Form.Label>
          <Form.Control
            id="company"
            name="company"
            aria-describedby=""
            type="text"
            placeholder="Enter Your Company Name"
            className="p-2 mb-2 focus-radius border border-secondary rad-8"
            required
          />
        </Form.Group>
        <Button
          type="submit"
          className="bg-dark border-secondary w-100 px-3 py-2 mt-2 rad-8"
          style={{ height: 48 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : submitButtonTitle}
        </Button>
      </Form>
    </div>
  );
};

export const ContactPage = () => {
  return (
    <>
      <div style={{ marginTop: "10vh" }}>
        <NavbarMain page="contactus" className="mb-5" />
      </div>
      <ContactUs submitButtonTitle="Submit" />
      <Container>
        <section className="d-flex mt-4 mt-lg-5">
          <div className="align-self-center">
            <h1 className="title-1" style={{ fontWeight: 400 }}>
              Start Your <br />
              <span className="text-extrabold">Own Experience</span> Journey
            </h1>
          </div>
        </section>
      </Container>
      <Footer background_lg={FooterBox_white} />
    </>
  );
};
export default ContactUs;
