import React from "react";

const ListPointer = ({is_live=false, no_padding=false}) => {
    return (
        <div className={`d-flex ${no_padding ? "px-0" : "px-3"}`} style={{marginLeft: no_padding ? "-9px" : "auto"}}>
            <div className={`${is_live ? "outer-circle-live": "outer-circle"} d-flex justify-content-center align-items-center`}>
                <div className={is_live ? "inner-circle-live": "inner-circle"}></div>
            </div>
            <div className={`ps-2 ${is_live ? "live-text": "upcoming-text"}`} style={{fontSize: 15, fontWeight: 700}}>
                {is_live ? "Live" : "Q4 2024"}
            </div>
        </div>
    );
}

export default ListPointer;