import "./App.css";
import Routers from "Routers";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import "react-toastify/dist/ReactToastify.css";
import "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";

const App = () => {
  setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 768 }, // ipad, ipad pro, ipad mini, etc
    { large: 992 }, // smaller laptops
    { xlarge: 1200 }, // laptops and desktops
    { xxlarge: 1400 }, // very large displays
  ]);
  return (
    <BreakpointProvider>
      <div className="p-0 user-select-none" style={styles.parentContainer}>
        <Routers />
      </div>
    </BreakpointProvider>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
  },
};

export default App;
