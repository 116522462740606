import React from "react";
import { Card,} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const RoadmapCard = ({
  title,
  subtitle,
  description,
  date,
  customDesc,
  link
}) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  return (
    <Card
      className={`bg-white h-100 border-0`}
      style={{
        borderRadius: medium ? 16 : 12,
        minHeight: 200,
      }}
    >
      <Card.Body className="d-flex flex-column justify-content-between mx-0 px-0 px-lg-3">
        <div className="mb-2">
          <div className="text-start">
            <div className="title-5 text-extrabold mb-3 fw-bolder">{title}</div>
            <div className="text-2 fw-bold mb-2" style={{ lineHeight: 1.2 }}>
              {subtitle}
            </div>
            <div className="mt-3">
              {customDesc ? customDesc : <p>{description}</p>}
            </div>
          </div>
        </div>
        {link != "" && (
          <div className="text-bold my-4">
            <a className="live-text" href={link}>Learn more</a>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default RoadmapCard;
