import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdCopyright } from "react-icons/md";
import { AiFillHeart } from "react-icons/ai";
const CopyrightFooter = () => {
  return (
    <Container
      fluid
      className="p-0 py-2"
      // className="bg-white bg-md-light bg-lg-dark text-lg-white text-center p-2"
    >
      <Container className="d-lg-flex justify-content-start">
        <div className="my-2">
          Own Experiences <MdCopyright size={18} /> 2024
        </div>
        {/* <div className="my-2">
          <p>Privacy Policy</p>
        </div> */}
      </Container>
    </Container>
  );
};

export default CopyrightFooter;
