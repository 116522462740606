import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ScrollToTop } from "web/components";
import Home from "web/pages/Home";
import axios from "axios";

const Routers = () => {
  
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="" element={<Home />}></Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routers;
