import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { menu_alt, logo } from "web/assets";
import { TbArrowUpRight } from "react-icons/tb";

const NavbarMain = ({ page, onJoinWaitlist }) => {
  const navigate = useNavigate();

  const [active, setActive] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [navbarClass, setNavbarClass] = useState("");

  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => setShowMenu(true);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setNavbarClass("bg-white");
    } else {
      setNavbarClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        id="navbar"
        variant="light"
        // className={`position-fixed fixed-top ${navbarClass}`}
        className={`${navbarClass}`}
        style={{ height: "10vh" }}
      >
        <Container fluid className="px-3 px-md-4 px-md-5">
          <Navbar.Brand as={Link} to="/">
            {/* small down */}
            <div className="d-block d-md-none">
              <img
                src={logo}
                className="d-inline-block align-top"
                alt="Own Experiences"
                width={140}
              />
            </div>
            {/* medium up */}
            <div className="d-none d-md-block mt-3">
              <img
                src={logo}
                className="d-inline-block align-top"
                alt="Own Experiences"
                width={180}
              />
            </div>
          </Navbar.Brand>
          {/* medium down(necessary) */}
          <div className="d-block d-lg-none">
            <div className="p-2 border rounded" onClick={handleShowMenu}>
              <img
                src={menu_alt}
                className="d-inline-block align-top"
                alt="Own Experiences"
              />
            </div>
          </div>
          {/* large up */}
          <div className="d-none d-lg-block w-100">
            <div className="d-block">
              <Nav
                activeKey={active}
                onSelect={(selectedKey) => setActive(selectedKey)}
                // className="ml-auto" style={{ marginRight: 36 }}
                className="d-flex"
              >
                {/* <Nav.Link
                  // as={Link} to="/ourecosystem"
                  className={`me-2 nav-link focus-none ${page == "ourecosystem" ? "active" : ""}`}
                  eventKey="ourecosystem"
                >
                  <Button className="bg-white border-0 text-dark focus-none"> Our Ecosystem </Button>
                </Nav.Link>

                <Nav.Link
                  // as={Link} to="/edaa"
                  className={`me-2 nav-link focus-none ${page == "edaa" ? "active" : ""}`}
                  eventKey="edaa"
                >
                  <Button className="bg-white border-0 text-dark focus-none"> EDAA™ </Button>
                </Nav.Link>

                <Nav.Link
                  // as={Link} to="/solutions"
                  className={`me-2 nav-link focus-none ${page == "solutions" ? "active" : ""}`}
                  eventKey="solutions"
                >
                  <Button className="bg-white border-0 text-dark focus-none"> Solutions </Button>
                </Nav.Link>

                <Nav.Link
                  as={Link} to="/aboutus"
                  className={`me-2 nav-link focus-none ${page == "aboutus" ? "active" : ""}`}
                  eventKey="aboutus"
                >
                  <Button className="bg-white border-0 text-dark focus-none"> About Us </Button>
                </Nav.Link> */}

                {/* {!isAuthenticated && (
                  <Nav.Link
                    as={Link} to="/login"
                    className={`me-2 nav-link focus-none`}
                    eventKey="login"
                  >
                    <Button className="sitebtn btn-white px-3 py-2"> Login </Button>
                  </Nav.Link>
                )} */}

                <Nav.Link
                  // as={Link} to="/joinwaitlist"
                  onClick={onJoinWaitlist}
                  className={`nav-link focus-none ms-auto ${
                    page == "joinwaitlist" ? "focus-none" : ""
                  }`}
                  // eventKey="joinwaitlist"
                >
                  <Button
                    className="sitebtn btn-dark btn-outline-dark px-4 py-2"
                    onClick={onJoinWaitlist}
                  >
                    Get Early Access
                    <TbArrowUpRight size={24} className="ms-2" />
                  </Button>
                </Nav.Link>
              </Nav>
            </div>
          </div>
        </Container>
      </Navbar>
      {/* off canvas */}
      <Offcanvas
        show={showMenu}
        onHide={handleCloseMenu}
        placement="end"
        className="d-block d-lg-none w-100 border-0"
      >
        <Offcanvas.Header closeButton className="p-4 pb-0">
          {/* <Link to="/"> */}
          <div
            onClick={() => {
              handleCloseMenu();
              navigate("/");
            }}
          >
            <img
              src={logo}
              className="d-inline-block align-top"
              alt="Own Experiences"
              width={140}
            />
          </div>
          {/* </Link> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div className="text-center">
              {/* <Link to="/ourecosystem">
                <p className="mb-4 text-dark">Our Ecosystem</p>
              </Link>
              <Link to="/edaa">
                <p className="mb-4 text-dark"> EDAA™</p>
              </Link>
              <Link to="/solutions">
                <p className="mb-4 text-dark">Solutions</p>
              </Link>
              <Link to="/aboutus">
                <p className="mb-4 text-dark">About Us</p>
              </Link> */}
              {/* <Link onClick={onJoinWaitlist}> */}
              <div className="my-4">
                <Button
                  className="sitebtn btn-outline-dark px-3 py-2"
                  onClick={onJoinWaitlist}
                >
                  Get Early Access
                  <TbArrowUpRight size={24} />
                </Button>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NavbarMain;
