import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
} from "react-bootstrap";
import {
  FooterBox_white,
  homeCoverVideo,
  linesAnimationImage,
  logo,
} from "web/assets";
import { useMediaQuery } from "react-responsive";
import ContactUs from "./ContactUs";
import { TbArrowUpRight } from "react-icons/tb";
import { Section2Item, RoadmapCard } from "web/sections";
import { CopyrightFooter, Footer, NavbarMain, ListPointer } from "web/components";

const points = [
  {
    title: "Human-Centered Solutions",
    description:
      "Experience Made Personal. Build upon your client's genuine needs. Make your company future-ready by taking your AI-powered solutions to a human level.",
  },
  {
    title: "Monetize Your Talent",
    description:
      "Do you have an idea for better-serving humanity's future through technology? We want to listen to it and serve you as a speaker. Our ecosystem enables custom solutions with ethical user profiling, providing a deeper understanding and new revenue streams.",
  },
  {
    title: "Define Ethics for Your AI Model",
    description:
      "Many definitions of ethics exist in an interconnected world that constantly evolves. We bring you the framework to define and certify what ethics means for your audience.",
  },
  {
    title: "Keep Your Model's Privacy Secured",
    description:
      "Blockchain infrastructure to ensure the readiness for your AI-powered solution. Deliver what your client needs when and how they require it, preserving their privacy.",
  },
];

const roadmapData = [
  {
    key: 1,
    title: "VirtuE Deep-Tech",
    subtitle:
      "Making technology understand and respond to human emotions.",
    text: "VirtuE brings scientific Theory of Mind AI to create deeper connections between humans and technology. Our proprietary foundational multi-model (EDAA ™) empowers a new generation of personalized experiences by understanding users' psychology without requiring brute-forcing learning.",
    date: "Live",
    link: "https://www.virtuedeep.tech/",
  },
  {
    key: 2,
    title: "Supernova Advisory",
    subtitle:
      "Hands-on advisory for Corporations and Investors concerned about business continuity.",
    text: "Your strategic partner for navigating uncertain, complex, and unpredictable business challenges, empowering your organization to become future-ready. We guide today's leaders to embrace human-centered business models, unlocking new opportunities for transformation and innovation.",
    date: "Live",
    link: "https://www.mysupernova.io/",
  },
  {
    key: 3,
    title: "The Human-Centered [Venture] Builder",
    subtitle: "The launchpad for tomorrow’s human-centered business.",
    text: "Bring your ideas to life with OWN’s ecosystem force. We're the launchpad for prototype-ready startups, specializing in pre-seed funding and support. We want to fuel human-centered solutions with our deep-tech inventions.",
    date: "Q4 2024",
    link: "",
  },
  {
    key: 4,
    title: "Decentralized Deep-Tech Chain",
    subtitle: "The Future of the Human-Centered Economy",
    text: "In a world where technology is becoming a commodity, we envision a new human-centered economy that ensures equitable opportunities, preserves users’ privacy, and champions authenticity.",
    date: "Q4 2024",
    link: "",
  },
  {
    key: 5,
    title: "Liquid Ethics for Responsible AI",
    subtitle: "#label-less and unbiased artificial intelligence",
    text: "Pioneering the liquid understanding of ethics in a data-driven world. Due to the fact that responsiveness in AI goes beyond data privacy and ownership, we empower fair practices for individuals, organizations, and legislation.",
    date: "Q4 2024",
    link: "",
  },
];

const Home = () => {
  const small = useMediaQuery({ query: "(max-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [pageScroll, setPageScroll] = useState(0);

  const joinWaitlistRef = useRef(null);
  const [tab, setTab] = useState("investor");
  const [scrollProgress, setScrollProgress] = useState(0);
  const [bottomBlurVisible, setBottomBlurVisible] = useState(false);

  const linesAnimationRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
    const handlePageScroll = () => {
      setPageScroll(window.scrollY);

      let start = 785;
      let end = 2300;
      if (window.scrollY > start - 200 && window.scrollY < end - 200) {
        setBottomBlurVisible(true);
      } else {
        setBottomBlurVisible(false);
      }
      if (window.scrollY > start && window.scrollY < end) {
        setScrollProgress(parseInt((window.scrollY - start) * 250 / (end - start)) + 50);
      }
    };

    window.addEventListener("scroll", handlePageScroll);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add your custom logic here
          }
        });
      },
      { threshold: 1.0 }
    );

    if (linesAnimationRef.current) {
      observer.observe(linesAnimationRef.current);
    }

    return () => {
      window.removeEventListener("scroll", handlePageScroll);
      if (linesAnimationRef.current) {
        observer.unobserve(linesAnimationRef.current);
      }
    };
  }, []);


  return (
    <>
      <div className="user-select-none border">
        <div className="video-background">
          <video autoPlay muted loop>
            <source src={homeCoverVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Container
            fluid
            className="content position-relative"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="position-absolute top-0 w-100">
              <NavbarMain
                onJoinWaitlist={() => scrollToSection(joinWaitlistRef)}
              />
            </div>
            <Container className="d-flex justify-content-center align-items-center min-height mt-5 w-100">
              <div className="text-center">
                <div className="mb-4">
                  <h1 className="title-1 text-extrabold">
                    Your AI-powered solution <br />
                  </h1>
                  {large ? (
                    <div>
                      <h1 className="title-1 text-extrabold">
                        <i>now</i>&nbsp;responding to
                      </h1>
                      <h1 className="title-1 text-extrabold">
                        individual uniqueness
                      </h1>
                    </div>
                  ) : (
                    <h1 className="title-1 text-extrabold">
                      <i>now</i>&nbsp;responding to individual uniqueness
                    </h1>
                  )}
                </div>
                <div
                  className="mx-auto my-4"
                  style={{ width: large ? "60%" : "80%" }}
                >
                  <div
                    className="text-2 fw-bold mb-2"
                    style={{ lineHeight: 1.2 }}
                  >
                    OWN is a decentralized ecosystem that enables human-centered
                    solutions by safely catering to each individual's uniqueness.
                  </div>
                </div>
                <Button
                  className="sitebtn btn-outline-dark px-4 py-2"
                  onClick={() => scrollToSection(joinWaitlistRef)}
                  style={{ height: 48 }}
                >
                  Get Early Access
                  <TbArrowUpRight size={24} className="ms-2" />
                </Button>
              </div>
            </Container>
          </Container>
        </div>

        <section className="d-none d-lg-block bg-white position-relative">
          <Container className="d-flex">
            <Row>
              <div
                className="sticky-top"
                style={{
                  background:
                    "linear-gradient(0deg, #FFFFFF00, #FFFFFFab, #FFFFFF)",
                  height: "200px",
                  width: "100%",
                  top: "0px",
                }}
              ></div>
              <div
                className={`${bottomBlurVisible ? 'fixed-bottom' : 'position-absolute'} `}
                style={{
                  background:
                    "linear-gradient(0deg, #FFFFFF, #FFFFFFab, #FFFFFF00)",
                  bottom: "0px",
                  height: "250px",
                  width: "100%",
                }}
              ></div>
              <Col md={1} className="d-none d-md-flex justify-content-center pb-5">
                <div
                  className="sticky-top mb-5"
                  style={{
                    height: 300,
                    top: 200,
                    backgroundColor: "#ddd"
                  }}
                >
                  <div
                    className="scrollbar"
                    style={{ height: `${scrollProgress}px` }}
                  ></div>
                </div></Col>
              <Col md={6} className="mb-5">
                {points.map((item, i) => (
                  <Section2Item
                    key={i}
                    title={item.title}
                    description={item.description}
                  />
                ))}
              </Col>
              <Col md={5}>
                <div className="sticky-top" ref={linesAnimationRef} style={{ transform: "scale(1.4) rotate(-24deg)", zIndex: 20, marginRight: -100, marginTop: -100 }}>
                  <Image src={linesAnimationImage} alt="Lines Animation" />
                  {/* <AnimatedSVG  /> */}
                  {/* <SvgAnimation percentage={(scrollProgress - 50) * 100 / 250} /> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="bg-white d-lg-none">
          <Container
            fluid
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${linesAnimationImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Container className="border-bottom">
              <div>
                <div className="py-5 px-4 w-100">
                  {points.map((item, i) => (
                    <Section2Item
                      key={i}
                      title={item.title}
                      description={item.description}
                    />
                  ))}
                </div>
              </div>
            </Container>
          </Container>
        </section>

        <section className="bg-light">
          <Container className="d-flex align-items-center min-height py-5">
            <div className="d-flex flex-column justify-content-center align-items-center my-5 w-100 pb-md-4">
              <div className="mb-4">
                <img
                  src={logo}
                  className="d-inline-block align-top"
                  alt="OWN Experiences"
                  width={large ? 240 : 180}
                />
              </div>
              <div className="text-center my-4 my-lg-5 mt-lg-0 mb-lg-4 text-extrabold">
                <div>
                  <h1 className="title-1 font-bold">
                    Enablers of the Next Generation of Augmented Humanity
                  </h1>
                </div>
              </div>

              <div className="w-lg-75">
                <p className="text-center">
                  We provide an ecosystem that enhances your AI-powered solution by safely accommodating the unique characteristics of each individual and collaborating with partners to establish a human-centered wellbeing economy.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <section className="bg-white">
          <div className="text-center mb-4 mb-lg-5 pt-4 mt-5">
            <Container fluid className="py-md-5">
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <h1 className="title-1 font-bold text-start mx-3 mx-lg-0">OWN Experiences Roadmap</h1>
                </Col>
              </Row>
            </Container>
          </div>
          <Container fluid className="p-0 overflow-hidden">
            <Row className="d-flex d-lg-none py-5">
              {roadmapData.map((item, i) => (
                <div
                  key={item.key}
                  md={6}
                  lg={4}
                  className={`p-0`}
                >
                  <Row>
                    <Col xs={1}></Col>
                    <Col xs={10} className="d-flex flex-column">
                      <div className="line-div-vertical-xs">

                      </div>
                      <ListPointer is_live={item.key <= 2} no_padding={true} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1}></Col>
                    <Col xs={1} className="d-flex">
                      <div className="line-div-vertical">

                      </div>
                    </Col>
                    <Col xs={9} className="p-0 py-3">
                      <RoadmapCard
                        title={item.title}
                        subtitle={item.subtitle}
                        description={item.text}
                        date={item.date}
                        link={item.link}
                        index={i}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </Row>
          </Container>

          <Row className="d-none d-lg-flex">
            <Col lg={2} className="d-flex align-items-center p-0">
              <div className="line-div flex-grow-1"></div>
            </Col>
            <Col lg={4} className="d-flex align-items-center p-0">
              <ListPointer is_live={true} /><div className="line-div flex-grow-1"></div>
            </Col>
            <Col lg={4} className="d-flex align-items-center p-0">
              <ListPointer is_live={true} />
              <div className="d-flex flex-column justify-content-between align-items-end h-100 flex-grow-1 p-0">
                <div className="flex-grow-1 p-0">
                </div>
                <div className="line-div w-100 p-0"></div>
                <div className="d-flex flex-grow-1 p-0">
                  <div className="line-div-vertical-wt-height h-100">
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="d-none d-lg-flex">
            <Col lg={2} className="p-0"></Col>
            {roadmapData.slice(0, 2).map((item, i) => (
              <Col
                key={item.key}
                md={6}
                lg={4}
                className={`p-0 py-5 ${item.key == 2 ? "border-right-vertical" : ""}`}
              >
                <RoadmapCard
                  title={item.title}
                  subtitle={item.subtitle}
                  description={item.text}
                  date={item.date}
                  link={item.link}
                  index={i}
                  key={item.key}
                />
              </Col>
            ))}
          </Row>
          <Row className="d-none d-lg-flex">
            <Col lg={2} className="d-flex align-items-center p-0">
              <div className="line-div flex-grow-1"></div>
            </Col>
            <Col lg={8}>
              <Row className="p-0">
                <Col lg={4} className="d-flex align-items-center p-0">
                  <ListPointer /><div className="line-div flex-grow-1"></div>
                </Col>
                <Col lg={4} className="d-flex align-items-center p-0">
                  <ListPointer /><div className="line-div flex-grow-1"></div>
                </Col>
                <Col lg={4} className="d-flex align-items-center p-0">
                  <ListPointer />
                  <div className="d-flex flex-column justify-content-between align-items-end h-100 flex-grow-1 p-0">
                    <div className="flex-grow-1 p-0">
                      <div className="line-div-vertical-wt-height h-100 p-0">
                      </div>
                    </div>
                    <div className="line-div w-100"></div>
                    <div className="flex-grow-1">
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="d-none d-md-flex">
            <Col lg={2}></Col>
            <Col lg={8}>
              <Row>
                {roadmapData.slice(2, 5).map((item, i) => (
                  <Col
                    key={item.key}
                    md={6}
                    lg={4}
                    className="p-0 mb-4 py-5"
                  >
                    <RoadmapCard
                      title={item.title}
                      subtitle={item.subtitle}
                      description={item.text}
                      date={item.date}
                      link={item.link}
                      index={i}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </section>

        <section className="bg-light">
          <Container
            className="d-flex flex-column min-height py-5"
            ref={joinWaitlistRef}
          >
            <div className="d-flex mx-auto w-100 w-lg-50 border-bottom border-dark pb-2">
              <Button
                className={`w-100 py-2 border-0 focus-none ${tab === "investor"
                  ? "bg-dark text-white"
                  : "bg-transparent text-dark text-extrabold"
                  }`}
                style={{ height: large ? 44 : 40 }}
                onClick={() => {
                  setTab("investor");
                }}
              >
                For Investors
              </Button>
              <Button
                className={`w-100 py-2 border-0 focus-none ${tab === "earlyaccess"
                  ? "bg-dark text-white"
                  : "bg-transparent text-dark text-extrabold"
                  }`}
                style={{ height: large ? 44 : 40 }}
                onClick={() => {
                  setTab("earlyaccess");
                }}
              >
                Get Early Access
              </Button>
            </div>
            <div className="d-flex justify-content-center mt-5 w-100">
              <Row xs={1} lg={2} className="w-100">
                <Col>
                  <div className="d-flex mb-3 mb-lg-0">
                    {tab === "earlyaccess" ? (
                      <h1
                        className="title-1"
                        style={{ fontWeight: 400, lineHeight: 1.2 }}
                      >
                        Start Your <br />
                        <span className="text-extrabold">
                          OWN Experiences
                        </span>{" "}
                        Journey
                      </h1>
                    ) : (
                      <h1
                        className="title-1"
                        style={{ fontWeight: 400, lineHeight: 1.2 }}
                      >
                        Investing in <br />
                        <span className="text-extrabold">Human-Centered</span>
                        <br />
                        <span className="text-extrabold">
                          Ethical Innovation
                        </span>
                      </h1>
                    )}
                  </div>
                </Col>
                <Col>
                  <ContactUs
                    type={tab}
                    submitButtonTitle={
                      tab === "investor" ? "More Information" : "Get Early Access"
                    }
                  />
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section className="bg-white">
          <Footer background_lg={FooterBox_white} />
        </section>
        <section className="bg-light">
          <CopyrightFooter />
        </section>
      </div >
    </>
  );
};

export default Home;
